import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import { StaticImage } from 'gatsby-plugin-image';
// import { email } from '@config';

const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 40%;
    flex-direction: column;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  max-width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const ProfileImage = styled.div`
  flex-shrink: 0;
  width: 300px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  border: 3px solid var(--accent-image);
  border-radius: 50%;
  transition: var(--transition);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    transform: scale(1.1);
    transition: var(--transition);
  }

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  padding: 0;
  background-color: 'red';

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--accent);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--text);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin: 50px 20px 0 0;
    font-size: var(--fz-lg);
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, I'm</h1>;
  const two = <h2 className="big-heading">Lakshya Agarwal</h2>;
  const three = (
    <h3 className="medium-heading">
      Nice to meet you!{' '}
      <span role="img" aria-label="hello">
        👋
      </span>
    </h3>
  );

  const four = (
    <>
      <p>
        I'm an AI engineer and full-stack developer passionate about building innovative solutions.
        With expertise in machine learning and web development, I create impactful projects that
        bridge cutting-edge AI with robust applications. Dive into my portfolio and blog to explore
        how I'm shaping the future of technology.
      </p>
    </>
  );
  const five = (
    <div>
      <a className="email-link" href="#projects" rel="noreferrer">
        <span>👨‍💻</span> Check out my projects!
      </a>
      <a className="email-link" href="/blogs" rel="noreferrer">
        <span>📖</span> Read the blog!
      </a>
    </div>
  );

  const profilePhoto = (
    <ProfileImage>
      <StaticImage
        className="img"
        src="../../images/me_formal.jpeg"
        width={1000}
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        alt="Headshot"
      />
    </ProfileImage>
  );

  const introText = (
    <TextContainer>
      {one}
      {two}
      {three}
      {four}
      {five}
    </TextContainer>
  );

  const introContent = (
    <IntroContainer>
      {introText}
      {profilePhoto}
    </IntroContainer>
  );

  const items = [introContent];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
