import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
`;

const StyledProject = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--light-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition);

  &:hover,
  &:focus {
    box-shadow: var(--box-shadow-hover);
  }

  .project-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .project-overline {
      margin: 10px 0;
      color: var(--accent);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      font-weight: 400;
    }

    .project-title {
      color: var(--lightest-text);
      font-size: clamp(24px, 5vw, 28px);
      margin: 0 0 20px;

      a {
        position: static;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
          color: var(--accent);
        }
      }
    }

    .project-description {
      color: var(--light-text);
      font-size: var(--fz-lg);
      margin-bottom: 20px;
    }

    .project-tech-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0 0 20px;

      li {
        margin: 0 10px 5px 0;
        color: var(--light-text);
        font-family: var(--font-mono);
        font-size: var(--fz-xs);
        white-space: nowrap;
      }
    }

    .project-links {
      display: flex;
      align-items: center;
      margin-top: auto;

      a {
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 10px;

        &.external {
          svg {
            width: 22px;
            height: 22px;
            margin-top: -4px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .project-image {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    overflow: hidden;
    height: 400px;
    box-shadow: var(--box-shadow);
    background-color: var(--light-bg);

    .img {
      width: 100%;
      height: auto;
      transition: var(--transition);
      border-radius: var(--border-radius);
      object-fit: cover;

      &:hover,
      &:focus {
        filter: grayscale(70%) contrast(1) brightness(90%);
      }
    }
  }
`;

const Featured = () => {
  const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              tech
              github
              external
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <section id="projects">
      <h2 className="numbered-heading" ref={revealTitle}>
        Notable Projects
      </h2>

      <StyledProjectsGrid>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, tech, github, cover } = frontmatter;
            const image = getImage(cover);

            return (
              <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                <div className="project-image">
                  <a href={external ? external : github ? github : '#'}>
                    <GatsbyImage image={image} alt={title} className="img" />
                  </a>
                </div>

                <div className="project-content">
                  <div>
                    <p className="project-overline">Featured</p>

                    <h3 className="project-title">
                      <a href={external}>{title}</a>
                    </h3>

                    <div
                      className="project-description"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />

                    {tech.length && (
                      <ul className="project-tech-list">
                        {tech.map((tech, i) => (
                          <li key={i}>{tech}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="project-links">
                    {github && (
                      <a href={github} aria-label="GitHub Link">
                        <Icon name="GitHub" />
                      </a>
                    )}
                    {external && (
                      <a href={external} aria-label="External Link" className="external">
                        <Icon name="External" />
                      </a>
                    )}
                  </div>
                </div>
              </StyledProject>
            );
          })}
      </StyledProjectsGrid>
    </section>
  );
};

export default Featured;
