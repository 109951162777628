module.exports = {
  email: 'lakshya.agarwal@mail.mcgill.ca',

  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/lakshyaag',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/lakshyaag',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/lakshyaag',
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/lakshyaag',
    },
    {
      name: 'Steam',
      url: 'http://steamcommunity.com/id/lakshyaag/',
    },
    {
      name: 'Ethereum',
      url: 'https://etherscan.io/address/0xc51e5b2c1f9978b195e926a367945aa786b4a4cd',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Work',
      url: '/#jobs',
    },
    {
      name: 'Projects',
      url: '/#projects',
    },
    {
      name: 'Education',
      url: '/#education',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
    {
      name: 'Blog',
      url: '/blogs',
    },
  ],

  colors: {
    accent: '#ec058e',
    bg: '#151315',
    darkBg: '#0b0a0b',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
