import React, { useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
  padding: 5px;
  margin: 10px;
  font-size: 16px;
  font-family: var(--font-mono);
  font-weight: bold;
  color: var(--text);
  &:before {
    content: '${props => (props.isExpanded ? '▲' : '▶ ')}';
    display: inline-block;
  }
  &:hover {
    color: var(--accent);
  }
`;

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  position: relative;
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--accent);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--accent-image);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(70%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--bg);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--accent-image);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(prevState => !prevState);
  };

  const skills = [
    'Python',
    'LLMs',
    'Generative AI',
    'Data Science',
    'R',
    'JavaScript',
    'SQL',
    'Tableau / PowerBI',
    'Excel',
    'Git',
    'Databricks',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            {isExpanded ? (
              <>
                <p>
                  Hi! I'm Lakshya, a 24-year-old from India who loves adventures and solving
                  problems. My tech journey began in 2013 with HTML and JavaScript, which eventually
                  evolved into exploring data analytics and mastering Python and R.
                </p>
                <p>
                  As a former Private Equity Associate at Bain & Company, I honed my skills in
                  analyzing qualitative and quantitative data to support decision-making, working
                  with tools such as survey analytics, workforce analytics, and sentiment analytics.
                </p>

                <p>
                  Now, I'm expanding my horizons by pursuing Business Analytics at the prestigious
                  McGill University in Montreal, staying on the cutting edge of the AI space.
                </p>
                <p>
                  Outside of academics, my hobbies reflect my adventurous spirit and diverse
                  interests. I enjoy playing video games{' '}
                  <span aria-label="video-game" role="img">
                    🎮
                  </span>
                  , solving puzzles{' '}
                  <span aria-label="puzzle" role="img">
                    🧩
                  </span>
                  , watching F1{' '}
                  <span aria-label="race-car" role="img">
                    🏎
                  </span>
                  , travelling to new places{' '}
                  <span aria-label="travel" role="img">
                    🧳
                  </span>
                  , and capturing stunning landscape photographs{' '}
                  <span aria-label="camera" role="img">
                    📸
                  </span>
                  .
                </p>
                <p>
                  Driven by a commitment to lifelong learning, I eagerly look forward to connecting
                  with fascinating people and expanding my knowledge across various subjects, from
                  software and science to philosophy and human history.
                </p>
                <p>
                  It's a pleasure to meet you, and I hope this website offers a glimpse into my
                  world!
                </p>
              </>
            ) : (
              <>
                <p>Hey, my name is Lakshya Agarwal!</p>

                <p>
                  I'm an avid gamer, sci-fi reader, knowledge athlete, student of the universe. I
                  love to learn about how things work from first principles, which leads me to
                  having a diverse set of interests. Currently, I'm learning about large language
                  models (LLMs) and how to build products with them. Previously, I worked as a
                  Private Equity Associate at Bain & Company, where I honed my skills in analyzing
                  qualitative and quantitative data.
                </p>
                <p>
                  It's a pleasure to meet you, and I hope this website offers a glimpse into my
                  world!
                </p>
              </>
            )}
            <p>
              <StyledArrowButton isExpanded={isExpanded} onClick={toggleReadMore}>
                {' '}
                Read {isExpanded ? 'Less' : 'More'}
              </StyledArrowButton>
            </p>
            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me_formal.jpeg"
              width={500}
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
